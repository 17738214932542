import LinkInOutApp from '@components/LinkInOutApp';
import { NotFoundWrapper } from '@styles/global-styles';

export default function NotFoundPage() {
  return (
    <NotFoundWrapper className="error">
      <div className="container-fluid">
        <div className="--icon">
          <img src="/static/img/404.png" alt="" />
        </div>
        <span className="--title mb-1">Không tìm thấy</span>
        <span className="--subTitle">
          Trở lại
          <LinkInOutApp href="/" className="textHover link">
            <> Trang chủ</>
          </LinkInOutApp>{' '}
        </span>
      </div>
    </NotFoundWrapper>
  );
}
